.chat-container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: solid 1px green;
}

.chatMenu{
    border: solid 1px red;
    display: flex;
    width: 100%;
    height: 100%;
    /* position: relative; */
    /* position: sticky; */
    /* bottom: 0em;
    left: 0em; */
    /* background-color: darkorchid; */
}

.text-container{
    display: flex;
    justify-content: flex-start;
}

.chatRoom{
    overflow: scroll;
    border: solid 1px greenyellow;
    height: 100%;
    width: 100%;
}

.chatMessages{
    height: 20em;
    overflow-y: scroll;
    padding: 10px;
}

.message{
    padding: 1em;
    border: solid 1px #000;
}

.cName{
    position: relative;
    height: 20px;
    color: var(--main-col);
    /* width: 50%; */
}

input{
    width: 30em;
    background-color: var(--sec-col-op);
    opacity: 0.7;
    height: 2em;
    border: none;
    font-size: large;
}

.inputName{
    position: relative;
    /* position: absolute; */
    height: 20px;
    /* width: 50%; */
}

.cTime{
    width: 1fr;
}

.cName{
    width: 100%;
}
.cText{
    width: auto;
}


@media (max-width: 600px) {
    .chatMessages{
        max-height: 70em;
    }
}